.leads-lft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    height: 96%;
    width: 74.5%;
    overflow: hidden;
}

.leads-sidebar {
    width: 25%;
    background-color: #ffffff;
    border-radius: 5px;
    height: 100%;
    overflow: auto;



}

.lead-side-view-component {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
}

.leads-main {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    border: 1px solid #e9ecef;
    border-radius: 5px;
}

.leads-main-exist {
    width: 75%;
}

.leads-main-new {
    width: 100%;
}

.lead-profile-img {
    position: relative;
    margin: auto;
}

.lead-save-icon {
    padding: 15px 15px 0px 15px;
    color: #3e98c7;
    cursor: pointer;
}

.lead-profile-icon {
    width: 100px;
    height: 100px;
    color: #ffffff;
    background-color: #3e98c7;
    border-radius: 50%;
    border: 2px solid #3e98c7;
}

.lead-side-view-flx {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.lead-side-view-profile {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.lead-side-view-others {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    height: 50%;
    overflow: auto;
}

.lead-side-view-others-flx {}

.lead-side-view-title {

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    margin: 3px 10px 3px 10px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
}

.lead-side-view {

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 3px 10px 3px 10px;
    height: 100%;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    overflow-y: scroll;
}

.lead-name {
    margin: auto;
    font-size: 20px;
    font-weight: 600;
    color: #2c2828;
    padding: 10px 20px 10px;
    word-break: break-all;
    text-align: center;
}

.lead-address {
    font-size: 10px;
    text-align: center;
    word-break: break-all;
    color: #2c2828;
}

.lead-social-container {
    display: flex;
    justify-content: center;
    padding: 7px 7px;
}

.lead-social-icon {
    font-size: 17px;
    padding: 6px;
    border-radius: 50%;
    background-color: #2196f3;
    color: #fff;
    margin: 3px;

}

.lead-others-detail {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.lead-others {
    color: #fff;
    font-size: 13px;
    text-align: left;
}

.lead-others-title {
    font-weight: 600;
}

.lead-score {
    border-radius: 5px;
    margin: 0px 5px 5px 9px;
    padding: 3px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.lead-scorecard {
    width: 75px;
    margin: auto;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
}

.lead-score-detail {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.lead-score-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px;
}

.lead-score-icon {
    width: 20%;
    color: #2c2828;
}

.lead-score-icon-txt {
    width: 80%;
    color: #2c2828;
    font-size: 10px;
    word-break: break-all;
}

.lead-main-view {
    height: 100%;
}

.lead-main-tab {
    margin: 5px;
    height: 90%;
    overflow: auto;
}

.lead-main-tab-meeting {
    margin: 5px;
    overflow: hidden;
}

.lead-main-card {
    height: calc(100% - 50px);
    overflow: auto;
}

.lead-main-card-meeting {
    height: calc(100% - 50px);
    overflow: hidden;
}

.lead-main-card_sub-content {
    overflow: auto;
    height: calc(100% - 60px);
}


.lead-save-icon {
    padding: 15px 15px 0px 15px;
    color: #3e98c7;
}

.ticket-toggle-icon {
    padding: 15px 15px 0px 15px;
    color: #3e98c7;
}

.lead-save-icon:hover {
    background: #def1fb;
    height: 15px;
    width:15px;
    color: #54A6E0;
    border-radius: 50%;

}

.lead-meeting {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgba(40, 48, 54, 0.1);
    width: 100%;
    background-color: #fff;
}

.lead-meeting-header {
    display: flex;
    font-size: 14px;
    padding: 10px 0;
    color: #858B91;
    margin-bottom: 5px;
    flex-direction: row;

}

.lead-ticket-header {
    display: flex;
    font-size: 14px;
    padding: 10px 0;
    color: #454f58;
    margin-bottom: 5px;
    flex-direction: column;
}

.lead-ticket-subheader {
    font-size: 13px;
}

.lead-meeting-rht {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px;
}

.lead-ticket-rht {
    flex-direction: column !important;
}

.lead-meeting-lft {
    margin: auto;
    width: 85%;
    padding-left: 10px;
}

.lead-meeting-subject {
    display: flex;
    justify-content: space-between;
    color: #858B91;
    width: 100%;
    flex-direction: column;
}

.lead-completed-lable {
    background: rgb(50 170 82 / 10%);
    color: #32AA52;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.lead-timer-lable {
    margin-bottom: 6px;
}

.lead-Upcoming-lable {
    background: rgb(255 153 0 / 10%);
    color: #FF9900;
    padding: 5px;
    border-radius: 5px;
}

.lead-expire-lable {
    background: rgb(170 50 50 / 10%);
    color: rgb(241 100 100);
    padding: 5px;
    border-radius: 5px;
}

.lead-ticket-status {
    display: flex;
    flex-direction: row;
}

.lead-ticket-open {
    background: rgb(124 50 170 / 10%);
    color: #805cd7;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

}

.lead-ticket-closed {
    background: rgb(50 170 82 / 10%);
    color: #32AA52;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

}

.lead-ticket-pending {
    background: rgba(170, 126, 50, 0.1);
    color: #ffb300;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

}

.lead-ticket-others {
    background: rgb(84 166 224 / 10%);
    color: #54A6E0;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

}

.lead-ticket-overdue {
    background: rgb(170 50 50 / 10%);
    color: rgb(241 100 100);
    padding: 5px;
    border-radius: 5px;
}


.lead-meeting-subject p {
    margin: 0;
    font-size: 14px;
}

.lead-call-icon {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    background: rgb(50 170 82 / 10%);
    color: #32AA52;
}

.lead-view-ticket-icon {
    margin-left: 35px;
    background: rgb(84 166 224 / 10%) !important;
    color: #54A6E0 !important;
    cursor: pointer;
}

.lead-meetings-detail {
    margin-bottom: 5px;
    margin-left: 27px;
}

.lead-ticket-detail {
    padding-bottom: 5px;
    padding-left: 46px;
}

.lead-ticket-id {
    color: #3e98c7;
    font-weight: 600;
}

.lead-ticket-icon {
    color: #3e98c7;
    font-size: 17px;
    padding: 0px 20px 0px 10px;
}

.lead-meeting-subject-lft {
    width: 94%;
    margin-right: 10px;
}

.lead-meeting-subject-rht {
    width: 6%;
    margin: auto;
}

.lead-side-view-component-1 {
    height: calc(100% - 200px);
}

.lead-side-view-component-2 {
    height: 200px;
    overflow: auto;
    border-radius: 10px;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    margin: 10px 10px 1px 10px;
    background-color: #fff;
}

.lead-side-view-2 {
    height: 100%;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
}

.lead-notepad-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.lead-notepad-head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 70px;
    padding: 9px;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 6%);
    border-bottom: 1px solid #e1e1e1;

}

.lead-notepad-head1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #e1e1e1;
}

.lead-notepad-head-lft {
    font-size: 20px;
    padding: 6px;
    color: #3e98c7;
    font-weight: 600;
    width: 28%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lead-meetings-head-lft {

    width: 95% !important;

}

.lead-notepad-head-rht {
    padding: 9px;
    text-align: right;
    margin-top: 5px;
    flex: 1 1;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    height: 40px;
    max-height: 60px;


}

.lead-create-head-rht {
    width: 100%;
    height: 75%;
    padding: 10px;
    text-align: right;
    margin-top: 5px;
}


.lead-notepad-head-title {
    padding-top: 6px;
}

.lead-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 60px;
    max-height: 60px;

}

.lead-body {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: row;
    border: 1px solid #ececec;
    border-radius: 5px;
}

.lead-search {
    width: 90%;
}

.lead-header1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 70%;
}
.structureChange{
    display: flex;
}

.lead-header1-lft {
    width: 30%;
}
.lead-header1-rht {
    width: 100%;
    display: flex;
}
.lead-header2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lead-search-icon {
    position: absolute;
    padding: 15px;
    color: #9da2a6;
}

.lead-score-svg {
    width: 40px;
    height: 40px;
    border-radius: 5%;
    margin: 10px 4px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
}

.lead-score-svg img {
    padding: 4px;
}

.lead-score-svg~p {
    margin-top: 3px;
    margin-bottom: 4px;
}

.lead-score-svg-star-content {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.lead-score-svg-star-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}

.lead-score-svg-image {
    width: 30px;
    height: 30px;
}

.lead-score-svg-star {
    width: 15px;
    height: 15px !important;
}

.lead-ico-sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
}

.lead-ico-sec-rank {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #858B91;
    text-align: center;
    font-size: 15px;
}

.lead-ico-sec-rank-main {}

.dash_head~h1 {
    font-size: 36px;
    text-align: center;
    margin: auto;
    margin-top: 12px;
}

.lead-table-button {
    margin-top: 6px;
    color: #3e98c7;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 13px;
    cursor: pointer;

}

.lead-table-button:hover {
    background-color: #def1fb;
    color: #3e98c7;
}
.check{
    margin-top: 6px;
    background-color: #459dccc2;
    color: #ffffff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 13px;
    cursor: pointer;
}

.lead-note-padbody {
    height: 300px;
    overflow: hidden;
    padding: 10px;
}

.lead-note-pad {
    width: 100%;
    height: 250px;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    border: 1px solid #e9ecef !important;
    border-radius: 5px;
    resize: none;
}

.lead-filter-container {
    width: 500px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid #e9ecef !important;
}

.lead-filter-title {
    font-weight: 600;
    padding-top: 4px;
    color: rgb(34 150 243);
    font-size: 20px;
}


.lead-filter-container-head {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.lead-filter-icon {
    font-size: 24px;
    color: rgb(40 153 243);
    padding-right: 10px;
}

.lead-history-chat {
    width: 400px;
    height: 400px;
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, .2);
    overflow: auto;
    margin: 2px;
}

.lead-history-chat-head {
    width: 400px;
    height: 50px;
    background-color: #51CD5E;
    color: #fff;
    display: flex;
    flex-direction: row;
    margin: 2px;
}

.lead-whatsapp {
    margin: 10px;
}

.lead-filter-body {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    padding: 8px 24px;
}

.lead-filter-container .lead-filter-body {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 8px 15px;
    flex-wrap: wrap;

}

.lead-filter-parent {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.dialog-buttons {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

.dialog-btn1 {
    width: 130px;
    height: 40px;
    color: #fff;
    background-color: #2196f3;
    border-radius: 5px;
    border: none;
    margin: 10px;
    font-weight: 600;
}

.lead-shedule-icon {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    background: rgb(255 153 0 / 10%);
    color: #32AA52;
}


.lead-navigation-icon {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    background: rgb(84 166 224 / 10%);
    color: #54A6E0;
}

.lead-meeting-call-icon {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    background: rgb(50 170 82 / 10%);
}

.lead-meetings-view {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.lead-meetings-view-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 5px 10px 5px 5px;
}

.lead-meetings-view-header-text {
    width: 80%;
    padding-top: 8px;

}

.lead-meetings-view-header-button {
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.lead-bts {

    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    flex-direction: row;


}

.lead-meetings-view-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    flex: 1;
}

.lead-meeting-detail {
    color: #54A6E0;
    padding: 4px;
    font-weight: bolder;
    margin: 5px;
    text-align: center;
}

.lead-meeting-subdetail-flx {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f7f7f7;

}

.lead-meeting-subdetail-non-flx {
    display: flex;
    justify-content: space-between;


}

.lead-meeting-subdetail {
    font-size: 15px;
    padding: 5px;
    overflow-wrap: break-word;
    overflow: auto;
}

.lead-meeting-comment-body {
    overflow: auto;
    overflow-wrap: break-word;
    height: calc(100% - 50px);
}

.lead-meetings-view-body-lft {
    height: 40%;
    padding: 10px;

}

.lead-meeting-font {
    font-size: 14px;
    color: #858B91;
}

.lead-txt-lft {
    font-weight: 600;
    color: #000000;
}

.lead-txt-rgt {
    color: rgb(77, 77, 77);
}

.lead-txt-color {
    color: #50585f;
    font-size: 15px;
}

.lead-meeting-comment {
    text-align: left !important;
}

.lead-meetings-view-body-rht {
    border-radius: 5px;
    margin-top: 5px;
    border: 2px solid rgb(40 48 54 / 6%);
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%);
    display: flex;
    flex-direction: column;
    margin: 10px;
    height: calc(100% - 225px);
}

.lead-meeting-main-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

}

.lead-meeting-main-div-bottom {
    height: 90%;
    overflow: auto;
}

.lead-script-body {
    word-break: break-all;
    padding: 5px;
    font-size: 15px;
}

.lead-script-content {
    word-break: break-all;
}

.lead-script-head {
    color: #54A6E0;
    font-weight: bolder;
    padding: 5px;


}

.lead-script-link {
    color: #54A6E0;
    font-weight: 500;
    padding: 5px;
}

.lead-script-link:hover {
    color: #54A6E0;
    font-weight: 500;
    padding: 5px;
}

.lead-script-strong {
    margin: 0px 10px 0px 10px;
    color: #000000;
    font-size: 15px;
}



.cardbody {
    padding: 5px;
    margin: 5px;
    height: 148px;
    border-radius: 10px;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -30px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: auto;
    list-style: none;
    width: 50px;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    border: 0;
    border-top: 2px solid transparent;
    border-bottom: 10px solid transparent;
    border-radius: 100%;
    outline: none;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #3e98c7;
}

.lead-popover {
    border: none !important;
}

.lead-note-pad:active {
    border: 1px solid #e9ecef !important;

}

.lead-note-pad:focus {
    border: 1px solid #e9ecef !important;
}

.lead-dial-button-green {
    color: #32AA52 !important;
}

.lead-dial-button-red {
    color: #E43554 !important;
}

.lead-dial-button-blue {
    color: #2196f3 !important;
}


.lead-count {
    padding-top: 12px !important;
    font-weight: 600;
    font-size: 20px;
}

.lead-meeting-subject-p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.lead-autodial-name {
    text-align: left;

}

.lead-autodial-flx {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.lead-autodial-flx-lft {
    padding-right: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.lead-autodial-flx-lft-sno {
    margin-top: 10px !important;
}

.Leadnewsideview {
    display: flex;
    margin-bottom: 15px;
}

.headText {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;

    color: #57A8DD;
}

.lead-newsideview-svg-image-head {
    width: 20px;
}


.lead-newsideview-svg-image {}

.headname {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.headphone {
    margin-left: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.Leadwrapper {}

.wrapperformlabel {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 10px;
    color: gray;
}

.wrappervalue {
    display: flex;
}

.wrappervaluetext {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.wrappericon {
    margin-left: auto;
}

.Leadwrapper .hr {
    margin-top: 1px;
    color: #c0bfbf;
    height: 1px;
}

.Leadwrappercontent {
    margin-top: -15px;
}
.leadstatus{
    padding: 10px;
}
.leadstatustxt{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: gray;
}
.wa_icon {
    width: 22px !important;
    height: 22px !important;
    cursor: pointer;
}

.lead-save-icon {
    padding: 14px 15px 0px 13px;
    color: #3e98c7;
    cursor: pointer;
    margin-top: 4px;
}
.lead-save-icons {
    padding: 13px 12px 0px 12px;
    color: #3e98c7;
    cursor: pointer;

}
.lead-save-icons:hover {
    background: #def1fb;
    color: #54A6E0;
    border-radius: 50%;
    height: 50px;
    width: 50px;

}

#errorCustomFilterName {
    color: red
}

.lead-notepad-rht {
    display: flex;
    flex-direction: row;
}

.lead-notepad-rht-toggle{
    margin: auto;
    margin-top: 10px;
}
.settings{
    cursor: pointer;
}
.dialog-fileds{
    display: flex;
}
.label_cls-field{
    font-size: 1rem;
    padding-bottom: 10px;
    color: #2c2828;
    margin-left: 2%;
}

.field-check .form-check-input {
    width: 1rem;
    height: 1rem;
}
.dialog-header-flex-lft-field{
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    font-size: 1.25rem
}
.select_all {
    font-weight: 600;
}
.select_all input[type="checkbox"] {
    width: 1rem; 
    height: 1rem; 
    border: 2px solid #283036 ; 
  }
.align_content{
    margin-top: 0.7rem;
    margin-bottom: 0.7rem ;
}

@media screen and (max-width:768px) {
    .mobile_hide {
        display: none !important;
    }

    .page-main-container {
        width: 100%;
    }



    .leads-main-exist {
        width: 100% !important;
    }

    .leads-main-exist {
        width: 100% !important;
    }

    .Customform-body {
        grid-template-columns: auto !important;
    }

    .content {
        padding-right: 0px !important;
    }

    .desktop_hide {
        display: none;
    }

    /* .desktop_hide_ {
        display: none;
    } */
}

@media screen and (max-width:767px) {
    .mobile_hide {
        display: none !important;
    }

    .page-main-container {
        width: 100%;
    }

    .leads-sidebar {
        width: auto !important;
    }


    .leads-main-exist {
        width: 100% !important;
    }

    .leads-main-exist {
        width: 100% !important;
    }

    .Customform-body {
        grid-template-columns: auto !important;
    }

    .content {
        padding-right: 0px !important;
    }

    .desktop_hide {
        display: none;
    }

    /* .desktop_hide_ {
        display: none;
    } */

}

@media screen and (max-width:640px) {
    .mobile_hide {
        display: none !important;
    }

    .desktop_hide {
        display: none;
    }

    /* .desktop_hide_ {
        display: none;
    } */
}

@media screen and (max-width:577px) {

    .mobile_hide {
        display: none !important;
    }

    .desktop_hide {
        display: none;
    }

    /* .desktop_hide_ {
        display: none;
    } */

}

@media screen and (max-width:480px) {
    .mobile_hide {
        display: none !important;
    }

    .desktop_hide {
        display: none;
    }

}